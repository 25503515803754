import { AbstractApiService } from './AbstractApiService';
import {
    CreateUserDto,
    UserDetailDataDto,
    UserDetailResponseDto,
    UserListResponseDto,
    PasswordResetRequestResponseDto,
    WelcomeEmailResponseDto, UserDeleteResponseDto,
} from '../../../backend-types/types';
import { authenticationService } from './AuthenticationService';

class UserService extends AbstractApiService {

    async getCurrent(): Promise<UserDetailResponseDto> {
        return this.get('current');
    }

    async get(id: string): Promise<UserDetailResponseDto> {
        const response = await this.fetch(`user/${id}`);
        return await response.json() as UserDetailResponseDto;
    }

    async list(page = 1): Promise<UserListResponseDto> {
        const response = await this.fetch(`user?page=${page}`);
        return await response.json() as UserListResponseDto;
    }

    async delete(userId: string): Promise<UserDeleteResponseDto> {
        const response = await this.fetch(`user/${userId}`, {
            method: 'DELETE',
        });

        return await response.json() as UserDeleteResponseDto;
    }

    async update(userId: string, user: Partial<CreateUserDto>): Promise<UserDetailResponseDto> {
        const refreshLogin = await this.shouldLoginBeRefreshed(userId, user);
        const body = { ...user };
        if (!user.password) {
            delete body.password;
        }

        const response = await this.fetch(`user/${userId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        const newUserData = await response.json() as UserDetailResponseDto;

        if (refreshLogin) {
            await this.refreshLogin(newUserData.payload, refreshLogin);
        }

        return newUserData;
    }

    async create(user: CreateUserDto): Promise<UserDetailResponseDto> {
        const response = await this.fetch('user', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(user),
        });

        return await response.json() as UserDetailResponseDto;
    }

    async requestPasswordReset(userEmail: string): Promise<boolean> {
        const response = await this.fetch('password-reset/request', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: userEmail })
        });

        const responseData: PasswordResetRequestResponseDto = await response.json() as PasswordResetRequestResponseDto;

        return responseData.payload.success;
    }

    async sendWelcomeMail(userIds: string[]): Promise<Record<string, boolean>> {
        const response = await this.fetch('password-reset/account-activation', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userIds: userIds })
        });

        const responseData: WelcomeEmailResponseDto = await response.json() as WelcomeEmailResponseDto;

        return responseData.payload.success;
    }

    /**
     * Returns `null` if the password should not be refreshed and the new password if it should.
     */
    private async shouldLoginBeRefreshed(userId: string, user: Partial<CreateUserDto>): Promise<string|null> {
        // This update does not update the password, no refresh is needed.
        if (!user.password) {
            return null;
        }

        // Refresh is only needed if the current user is being updated AND the password is being changed.
        const currentUser = await this.getCurrent();
        if (currentUser.payload.id === userId) {
            return user.password;
        }

        return null;
    }

    private async refreshLogin(user: UserDetailDataDto, password: string): Promise<void> {
        await authenticationService.login(user.email, password);
    }
}

export const userService = new UserService();
