import React from 'react';
import { UserForm, UserFormData } from '../user/UserForm';
import { appLayoutService } from '../layout/AppLayoutService';
import { userService } from '../service/UserService';
import { Alert } from '@mui/lab';
import { serializeError } from '../common/helpers';
import { useNavigate } from 'react-router-dom';
import { CreateUserDto } from '../../../backend-types/src/user/dto/create-user.dto';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';

export function UserCreateRoute() {
    const [ loading, setLoading ] = React.useState<boolean>(false);
    const [ error, setError ] = React.useState<string|null>(null);
    const [ sendWelcomeMail, setSendWelcomeMail ] = React.useState<boolean>(true);
    const navigate = useNavigate();

    appLayoutService.changeContext({ title: 'Nutzer anlegen' })
    const user: UserFormData = {
        firstName: '',
        lastName: '',
        email: '',
        isAdmin: false,
        isEnabled: true,
        biography: '',
    };

    const onSubmit = async (user: CreateUserDto) => {
        setLoading(true);
        setError(null);
        try {
            const response = await userService.create(user);
            if (sendWelcomeMail) {
                await userService.sendWelcomeMail([ response.payload.id ]);
            }
            navigate(`/user/${response.payload.id}`);
        } catch (e) {
            setError(await serializeError(e));
            setLoading(false);
        }
    }

    return (
        <React.Fragment>
            <UserForm
                user={user}
                onSubmit={onSubmit}
                submitText="Anlegen"
                loading={loading}
                additionalCheckbox={
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={sendWelcomeMail}
                                    onChange={(e) => setSendWelcomeMail(e.target.checked)}
                                />
                            }
                            label="Willkommens E-Mails versenden"
                            disabled={loading}
                        />
                    </FormGroup>
                }
            />



            {error ? <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert> : null }
        </React.Fragment>

    );
}
