import React, { FunctionComponent, ReactNode, useState } from 'react';
import '../App.css';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    AppBar,
    Box,
    Card,
    CardContent,
    Container,
    CssBaseline,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
    useTheme,
} from '@mui/material';
import UserIcon from '@mui/icons-material/Person';
import { appLayoutService } from './AppLayoutService';
import { authenticationService } from '../service/AuthenticationService';
import uniLogo from './uni-logo.png';
import { useRefreshedToken } from '../App';
import { LoadingIndicator } from '../common/LoadingIndicator';
import { DebugInformation } from './DebugInformation';
import { UniFreiburgEnv } from '../service/UniFreiburgEnv';

const drawerWidth = 240;

interface AppLayoutProps {
    navigation: {
        [path: string]: {
            title: string,
            icon: ReactNode;
        }
    };
}

export const AppLayout: FunctionComponent<AppLayoutProps> = props => {
    const [ statePath, setStatePath ] = useState<string>('');
    const [ title, setTitle ] = useState<string>('');
    const [ floatingActionButton, setFloatingActionButton ] = useState<ReactNode | null>(null);
    const [ userMenuAnchor, setUserMenuAnchor ] = useState<HTMLElement | null>(null);
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const theme = useTheme();
    const hasRefreshed = useRefreshedToken();

    // React to context changes
    React.useEffect(() => {
        const key = appLayoutService.onAppContextChange(context => {
            setStatePath(pathname);
            setTitle(context.title);
            setFloatingActionButton(context.floatingActionButton);
        });

        return () => appLayoutService.offAppContextChange(key);
    }, [ pathname ]);

    // Reset context when route changes
    React.useEffect(() => {
        if (pathname !== statePath) {
            appLayoutService.changeContext({});
        }
    }, [ pathname, statePath ]);

    // Display a loader until the user session has been refreshed
    if (!hasRefreshed) {
        return (
            <AppLayoutFrame navigationSlot={null} toolbarSlot={null} floatingActionButtonSlot={null}>
                <LoadingIndicator />
            </AppLayoutFrame>
        );
    }

    const toggleUserMenuAnchor = (event: React.MouseEvent<HTMLButtonElement>) => {
        setUserMenuAnchor(userMenuAnchor === null ? event.currentTarget : null);
    };
    const logout = () => {
        authenticationService.logout();
        navigate('/');
    };

    return (
        <AppLayoutFrame

            navigationSlot={
                <React.Fragment>
                    <AppLayoutNavigation navigation={props.navigation} />
                    <Divider/>
                    <DebugInformation />
                </React.Fragment>
            }

            toolbarSlot={
                <React.Fragment>
                    <Typography
                        component="h2"
                        variant="h5"
                        color="inherit"
                        noWrap
                        sx={{
                            flexGrow: 1,
                            paddingLeft: theme.spacing(4),
                            lineHeight: 2,
                        }}
                    >
                        {title}
                    </Typography>
                    <IconButton color="inherit" onClick={toggleUserMenuAnchor}>
                        <UserIcon/>
                    </IconButton>
                    <Menu
                        open={userMenuAnchor !== null}
                        anchorEl={userMenuAnchor}
                        onClose={() => setUserMenuAnchor(null)}
                    >
                        <MenuItem onClick={() => logout()}>Logout</MenuItem>
                    </Menu>
                </React.Fragment>
            }

            floatingActionButtonSlot={floatingActionButton}
        >
            {props.children}
        </AppLayoutFrame>
    );
};


interface AppLayoutNavigationProps {
    navigation: {
        [path: string]: {
            title: string,
            icon: ReactNode;
        }
    }
}

const AppLayoutNavigation: FunctionComponent<AppLayoutNavigationProps> = props => {
    const {pathname} = useLocation();
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <List sx={{flexGrow: 1, mt: 4}}>
            {
                Object.entries(props.navigation).map(([ route, item ]) => (
                    <ListItemButton
                        sx={{
                            background: pathname.indexOf(route) === 0 ? theme.palette.grey.A200 : 'transparent',
                            borderRadius: '32px',
                            margin: `0 ${theme.spacing(1)}`,
                            mb: 1,
                        }}
                        key={route}
                        onClick={() => navigate(route)}
                    >
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.title}/>
                    </ListItemButton>
                ))
            }
        </List>
    )
};

interface AppLayoutFrameProps {
    navigationSlot: React.ReactNode|null;
    toolbarSlot: React.ReactNode|null;
    floatingActionButtonSlot: React.ReactNode|null;
}


const AppLayoutFrame: FunctionComponent<AppLayoutFrameProps> = props => {
    const theme = useTheme();

    let backgroundImage = uniLogo;
    if (UniFreiburgEnv.backgroundUrl) {
        backgroundImage = UniFreiburgEnv.backgroundUrl;
    }


    return (
        <Box
            component="div"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100vw',
                height: '100vh',
                overflow: 'hidden',
            }}
        >
            <CssBaseline/>
            <AppBar
                position="relative"
                sx={{zIndex: theme.zIndex.drawer + 1}}
            >
                <Toolbar>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{
                            width: `calc(${drawerWidth}px - ${theme.spacing(3)})`,
                            color: theme.palette.grey.A200,
                            borderRight: `1px solid ${theme.palette.divider}`,
                            flexShrink: 0,
                        }}
                    >
                        {UniFreiburgEnv.appTitle}
                    </Typography>
                    <Container sx={{display: 'flex'}}>
                        {props.toolbarSlot}
                    </Container>
                </Toolbar>
            </AppBar>

            <Box
                component="div"
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100vw',
                    height: '100vh',
                    overflow: 'hidden',
                }}>
                <Box
                    sx={{width: drawerWidth, flexShrink: 0}}
                    component="nav"
                >
                    <Drawer
                        variant="permanent"
                        open
                        sx={{
                            display: 'flex',
                            '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                        }}
                    >
                        <Toolbar/>
                        <Divider/>
                        {props.navigationSlot}
                    </Drawer>
                </Box>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        px: 3,
                        background: '#fafafa',
                        height: '100%',
                    }}
                >
                    <Container
                        sx={{
                            mt: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            position: 'relative',
                        }}
                    >
                        <Card sx={{zIndex: 1, maxHeight: `calc(100% - ${theme.spacing(6)})`, overflow: 'auto'}}>
                            <Box component="div" sx={{
                                position: 'absolute',
                                top: `calc(-1 * ${theme.spacing(3)})`,
                                left: theme.spacing(8),
                            }}>
                                {props.floatingActionButtonSlot}
                            </Box>
                            <CardContent sx={{ pt: 4 }}>
                                {props.children}
                            </CardContent>
                        </Card>
                        <img
                            src={backgroundImage}
                            style={{
                                position: 'absolute',
                                bottom: '0',
                                right: '0',
                                width: '65vw',
                                minWidth: '600px',
                                maxWidth: '1000px',
                                opacity: 0.4,
                                zIndex: 0,
                            }}
                        />
                    </Container>
                </Box>
            </Box>
        </Box>
    );
};
