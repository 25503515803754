import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useContentPage } from './ContentPageDetailRoute';
import { LoadingIndicator } from '../../common/LoadingIndicator';
import { ContentPageForm } from './ContentPageForm';
import {
    ContentPageModificationDto
} from '../../../../backend-types/src/content-pages/dto/content-page-modification.dto';
import { contentPageService } from '../../service/ContentPageService';
import { serializeError } from '../../common/helpers';
import { Alert } from '@mui/lab';
import { appLayoutService } from '../../layout/AppLayoutService';

export function ContentPageEditRoute() {
    const navigate = useNavigate();
    const params = useParams();
    const id = String(params.id);
    const [ isLoading, contentPage ] = useContentPage(id);
    const [ processing, setProcessing ] = useState<boolean>(false);
    const [ error, setError ] = useState<string | null>(null);

    if (isLoading) {
        return <LoadingIndicator />
    }

    appLayoutService.changeContext({ title: `Inhaltsseite bearbeiten: ${contentPage.title}` });

    const onSubmit = async (contentPage: ContentPageModificationDto) => {
        setProcessing(true);
        setError(null);

        try {
            const response = await contentPageService.update(id, contentPage);
            navigate(`/content-page/${response.payload.id}`);
        } catch (e) {
            setError(await serializeError(e));
            setProcessing(false);
        }
    }

    return (
        <React.Fragment>
            <ContentPageForm
                contentPage={contentPage}
                onSubmit={onSubmit}
                submitText="Speichern"
                loading={processing}
            />

            {error ? <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert> : null }
        </React.Fragment>
    )
}
