import React, { FunctionComponent } from 'react';
import { Box, useTheme } from '@mui/material';
import { UniFreiburgEnv } from '../service/UniFreiburgEnv';

export const DebugInformation: FunctionComponent = () => {
    const theme = useTheme();

    return (
        <Box
            component="div"
            sx={{
                padding: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: theme.palette.grey.A400,
                fontSize: '0.8rem',
            }}>
            CNCT version {UniFreiburgEnv.version} on {UniFreiburgEnv.apiBaseUrl}
        </Box>
    );
}
