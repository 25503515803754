import React, { FunctionComponent, useState } from 'react';
import { ImportFileOptions, userImportService } from '../service/UserImportService';
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    ListItem,
    ListItemText,
    MenuItem,
    Select, Switch,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { blue, green, grey, orange, red } from '@mui/material/colors';
import { LoadingIndicator } from '../common/LoadingIndicator';
import { serializeError } from '../common/helpers';
import { Alert } from '@mui/lab';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import {
    ReadImportFileField,
    ReadImportFileFieldPositionDto,
} from '../../../backend-types/src/user/dto/read-import-file.dto';
import { CreateUserWithWarningsDto } from '../../../backend-types/src/user/dto/read-import-file-response.dto';
import { userService } from '../service/UserService';
import { PromisePool } from '@supercharge/promise-pool';
import BoltIcon from '@mui/icons-material/Bolt';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

enum UserState {
    NotPersisted,
    Waiting,
    Persisting,
    SendingWelcomeMail,
    Success,
    Error,
}

interface UsersWithMetadata extends CreateUserWithWarningsDto {
    state: UserState;
    persistenceErrors: string[],
}

function addMetadataToUsers(users: CreateUserWithWarningsDto[]): UsersWithMetadata[] {
    const userWithMetadata: UsersWithMetadata[] = [];

    for (const user of users) {
        userWithMetadata.push({
            ...user,
            state: UserState.NotPersisted,
            persistenceErrors: [],
        });
    }

    return userWithMetadata;
}

export const UserImportRoute: FunctionComponent = () => {
    const [ rawFileContents, setRawFileContents ] = useState<string[][]>([]);
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ error, setError ] = useState<string | null>(null);
    const [ file, setFile ] = useState<File | null>(null);
    const [ users, setUsers ] = useState<UsersWithMetadata[]>([]);
    const [ fieldPositionsChangeable, setFieldPositionsChangable ] = useState<boolean>(true);
    const [ optionsDirty, setOptionsDirty ] = useState<boolean>(false);
    const [ sendWelcomeEmails, setSendWelcomeEmails ] = useState<boolean>(true);
    const [ options, setOptions ] = useState<ImportFileOptions>({
        fieldPositions: [ 'email', 'firstName', 'lastName' ],
        skipLines: [],
    });

    React.useEffect(() => {
        if (!file) {
            return;
        }

        setError(null);
        setLoading(true);
        userImportService.readFile(file, options)
            .then(response => {
                setUsers(addMetadataToUsers(response.users));
                setRawFileContents(response.contents);
                setLoading(false);
            })
            .catch(async err => {
                setError(await serializeError(err));
                setRawFileContents([]);
                setLoading(false);
            });
    }, [ file, options ]);

    const startImport = () => {
        users.forEach(user => user.state = UserState.Waiting);
        setUsers([ ...users ]);
        setFieldPositionsChangable(false);

        PromisePool.for(users).withConcurrency(5).process(async user => {
            user.state = UserState.Persisting;
            setUsers([ ...users ]);

            try {
                const createResponse = await userService.create(user);

                if (sendWelcomeEmails) {
                    user.state = UserState.SendingWelcomeMail;
                    setUsers([ ...users ]);
                    await userService.sendWelcomeMail([ createResponse.payload.id ]);
                }

                user.state = UserState.Success;
                setUsers([ ...users ]);
            } catch (err) {
                user.state = UserState.Error;
                user.persistenceErrors = [ await serializeError(err) ];
                setUsers([ ...users ]);
            }
        });
    };

    if (loading) {
        return <LoadingIndicator/>;
    }

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    if (!file) {
        return (
            <React.Fragment>
                <Typography variant="h4">Nutzer importieren</Typography>
                <Typography variant="body1" sx={{mb: 4}}>
                    Bitte wählen sie eine CSV Datei für den import aus.
                </Typography>

                <Typography variant="body1" sx={{mb: 4}}>
                    Standard Format:<br />
                    <strong>E-Mail, Vorname, Nachname</strong><br /><br />
                    Format kann nach dem Upload konfiguriert werden, wenn die Datei ein anderes Format hat.
                </Typography>

                <UserImportFileSelector onFileSelection={setFile}/>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Box sx={{mb: 2}}>
                <Typography variant="h4">Spaltenverteilung</Typography>
                <UserImportFieldSelector
                    rawFileContents={rawFileContents}
                    disabled={!fieldPositionsChangeable}
                    options={options}
                    onChange={options => { setOptionsDirty(false); setOptions(options) }}
                    onDirty={() => setOptionsDirty(true)}
                />
            </Box>

            <Alert severity="info" sx={{ opacity: optionsDirty ? 1 : 0, mb: 2 }}>
                Änderungen an der Spaltenverteilung müssen angewendet werden bevor der Import gestartet werden kann.
            </Alert>

            <Box sx={{ opacity: optionsDirty ? 0.5 : 1 }}>
                <Typography variant="h4">Vorschau</Typography>
                <UserImportTable users={users}/>
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'end', mt: 2}}>
                <Tooltip title="Sendet Nutzern eine Willkommens E-Mail mit einem Code, um das eigene Konto zu aktivieren">
                    <FormControlLabel
                        label="Willkommens E-Mails versenden"
                        control={
                            <Switch
                                checked={sendWelcomeEmails}
                                onChange={e => setSendWelcomeEmails(e.target.checked)}
                            />
                        }
                    />
                </Tooltip>
                <Button
                    variant="contained"
                    onClick={startImport}
                    disabled={optionsDirty}
                >
                    <BoltIcon sx={{ mr: 2 }} />
                    Importieren
                </Button>
            </Box>
        </React.Fragment>
    );
};

interface UserImportTableProps {
    users: UsersWithMetadata[];
}

const UserImportTable: FunctionComponent<UserImportTableProps> = props => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell/>
                        <TableCell>E-Mail Adresse</TableCell>
                        <TableCell>Vorname</TableCell>
                        <TableCell>Nachname</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                {props.users.map((user, index) => (
                    <TableRow key={index}>
                        <TableCell>
                            <UserImportState state={user.state} errors={user.persistenceErrors}/>
                        </TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.firstName}</TableCell>
                        <TableCell>{user.lastName}</TableCell>
                        <TableCell>
                            <UserImportWarnings warnings={Object.values(user.warnings)}/>
                        </TableCell>
                    </TableRow>
                ))}
            </Table>
        </TableContainer>
    );
};


interface UserImportFileSelectorProps {
    onFileSelection: (file: File | null) => void;
}

const UserImportFileSelector: FunctionComponent<UserImportFileSelectorProps> = props => {
    const onFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onFileSelection(event.target.files?.[0] ?? null);
    };

    return <input type="file" accept=".csv" onChange={onFileSelection}/>;
};

/*
 * Renders the warnings hinting at issues for the user to be imported.
 */
interface UserImportWarningsProps {
    warnings: string[],
}

const UserImportWarnings: FunctionComponent<UserImportWarningsProps> = props => {
    if (props.warnings.length === 0) {
        return null;
    }

    return <Tooltip title={
        <React.Fragment>
            {props.warnings.map(warning => (
                <ListItem key={warning}>
                    <ListItemText primary={warning}/>
                </ListItem>
            ))}
        </React.Fragment>
    }>
        <WarningIcon sx={{color: orange[500]}}/>
    </Tooltip>;
};

/*
 * Renders a small state indicator for a user with a popover for hints and error messages.
 */
interface UserImportStateProps {
    state: UserState;
    errors: string[];
}

const UserImportState: FunctionComponent<UserImportStateProps> = props => {
    if (props.state === UserState.NotPersisted) {
        return null;
    }

    let icon: React.ReactElement<any, any> = <WarningIcon sx={{color: orange[500]}}/>;
    let contents: React.ReactElement<any, any> | null = null;

    switch (props.state) {
    case UserState.Waiting:
        icon = <HourglassEmptyIcon sx={{color: grey[500]}}/>;
        contents = <p>Es wird gewartet bis dieser Nutzer angelegt wird</p>;
        break;

    case UserState.Persisting:
        icon = <CircularProgress sx={{color: blue[500]}}/>;
        contents = <p>Der Nutzer wird gespeichert</p>;
        break;

    case UserState.SendingWelcomeMail:
        icon = <AlternateEmailIcon sx={{color: blue[500]}}/>;
        contents = <p>Willkommensmail wird versendet.</p>;
        break;

    case UserState.Success:
        icon = <CheckIcon sx={{color: green[500]}}/>;
        contents = <p>Der Nutzer wurde erfolgreich angelegt.</p>;
        break;

    case UserState.Error:
        icon = <ErrorIcon sx={{color: red[500]}}/>;
        contents = (
            <React.Fragment>
                <p>Während des Speicherns sind Fehler aufgetreten</p>
                {props.errors.map(error => (
                    <ListItem key={error}>
                        <ListItemText primary={error}/>
                    </ListItem>
                ))}
            </React.Fragment>
        );
        break;
    }

    return <Tooltip title={contents}>{icon}</Tooltip>;
};

/*
 * Renders a table of the raw input data with select fields in order to select which column
 * is interpreted as which field.
 */
interface UserImportFieldPositionProps {
    disabled: boolean;
    options: ImportFileOptions;
    onChange: (options: ImportFileOptions) => void;
    onDirty: () => void;
    rawFileContents: string[][];
}

const UserImportFieldSelector: FunctionComponent<UserImportFieldPositionProps> = props => {
    const [ options, setOptions ] = useState<ImportFileOptions>(props.options);

    const updateOptions = (changes: Partial<ImportFileOptions>) => {
        setOptions({...options, ...changes});
        props.onDirty();
    };

    const updateFields = (key: ReadImportFileField, index: number) => {
        const positions: ReadImportFileFieldPositionDto = [ ...options.fieldPositions ];
        const previousPosition = positions.indexOf(key);
        if (previousPosition !== -1) {
            positions[previousPosition] = null;
        }
        positions[index] = key;

        updateOptions({ fieldPositions: positions });
    };

    const disabled: boolean = props.disabled;

    const SelectField = (props: { index: number }) => {
        return (
            <FormControl>
                <Select
                    disabled={disabled}
                    value={options.fieldPositions[props.index] ?? null}
                    onChange={event => updateFields(event.target.value as ReadImportFileField, props.index)}
                >
                    <MenuItem value="email">E-Mail Adresse</MenuItem>
                    <MenuItem value="firstName">Vorname</MenuItem>
                    <MenuItem value="lastName">Nachname</MenuItem>
                </Select>
            </FormControl>
        );
    };

    const toggleLineSkip = (index: number) => {
        if (options.skipLines.indexOf(index) === -1) {
            updateOptions({
                skipLines: [ ...options.skipLines, index ],
            })
        } else {
            updateOptions({
                skipLines: options.skipLines.filter(skipLine => skipLine !== index),
            });
        }
    };

    const header: string[] = Object.keys(props.rawFileContents[0] ?? {});

    return (
        <React.Fragment>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            {header.map((field, index) => (
                                <TableCell key={index}>
                                    <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <Box sx={{mr: 2}}>{field}</Box>
                                        <SelectField index={index}/>
                                    </Box>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {props.rawFileContents.map((row, rowIndex) => (
                        <TableRow key={`row-${rowIndex}`} sx={{
                            color: options.skipLines.indexOf(rowIndex) === -1 ? 'text.primary' : 'text.secondary',
                            textDecoration: options.skipLines.indexOf(rowIndex) === -1 ? 'none' : 'line-through',
                        }}>
                            <TableCell>
                                <Checkbox
                                    checked={options.skipLines.indexOf(rowIndex) === -1}
                                    onClick={() => toggleLineSkip(rowIndex)}
                                />
                            </TableCell>
                            {row.map((content, columnIndex) => (
                                <TableCell key={`cell-${rowIndex}-${columnIndex}`}>
                                    {content}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </Table>
            </TableContainer>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', mt: 2}}>
                <Button variant="contained" onClick={() => props.onChange(options)}>
                    <CheckIcon sx={{ mr: 2 }} />
                    Anwenden
                </Button>
            </Box>

            {process.env.NODE_ENV !== 'development' ? null : (
                <details>
                    <summary>Debugging information</summary>
                    <pre><code>{JSON.stringify(options, null, 4)}</code></pre>
                </details>
            )}
        </React.Fragment>
    );
};
