import { ReactNode } from 'react';

interface AppContext {
    title: string,
    floatingActionButton: ReactNode,
}

type AppContextListener = (context: AppContext) => void;

class AppLayoutService {
    private listeners: {[id: string]: AppContextListener} = {};
    private context: AppContext|null = null;

    onAppContextChange(listener: AppContextListener): string {
        if (this.context !== null) {
            listener(this.context);
        }

        const key = Math.random().toString(26).substr(2);
        this.listeners[key] = listener;

        return key;
    }

    offAppContextChange(key: string): void {
        delete this.listeners[key];
    }

    changeContext(context: Partial<AppContext>) {
        this.context = {
            title: context.title ?? '',
            floatingActionButton: context.floatingActionButton ?? null,
        };
        for (const listener of Object.values(this.listeners)) {
            listener(this.context);
        }
    }
}

// There is only a single instance in the whole app.
export const appLayoutService = new AppLayoutService();
