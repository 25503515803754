import React, { ReactElement } from 'react';
import { FunctionComponent } from 'react';
import { Box } from '@mui/material';


interface InlineBadgeProps {
    backgroundColor: string;
    borderColor: string;
    icon: ReactElement;
}

export const InlineBadge: FunctionComponent<InlineBadgeProps> = (props) => {
    return (
        <Box sx={{
            display: 'inline-flex',
            pr: 2,
            pl: 1,
            pb: 0,
            pt: 0.5,
            borderRadius: 4,
            border: '3px solid',
            borderColor: props.borderColor,
            backgroundColor: props.backgroundColor,
        }}>
            <Box sx={{ mr: 0.5, color: props.borderColor }}>{props.icon}</Box>
            {props.children}
        </Box>
    )
}
