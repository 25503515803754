import { AbstractApiService } from './AbstractApiService';
import {
    ContentPageDetailsResponseDto,
    ContentPageListResponseDto,
    ContentPageModificationDto,
} from '../../../backend-types/types';

class ContentPageService extends AbstractApiService {

    async get(id: string): Promise<ContentPageDetailsResponseDto> {
        const response = await this.fetch(`content-page/${id}`);
        return await response.json() as ContentPageDetailsResponseDto;
    }

    async list(): Promise<ContentPageListResponseDto> {
        const response = await this.fetch('content-page');
        return await response.json() as ContentPageListResponseDto;
    }

    async update(pageId: string, contentPage: Partial<ContentPageModificationDto>): Promise<ContentPageDetailsResponseDto> {
        const response = await this.fetch(`content-page/${pageId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(contentPage),
        });

        return await response.json() as ContentPageDetailsResponseDto;
    }

    async create(contentPage: ContentPageModificationDto): Promise<ContentPageDetailsResponseDto> {
        const response = await this.fetch('content-page', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(contentPage),
        });

        return await response.json() as ContentPageDetailsResponseDto;
    }

    async delete(id: string): Promise<void> {
        await this.fetch(`content-page/${id}`, {
            method: 'DELETE',
        });
    }
}

export const contentPageService = new ContentPageService();
