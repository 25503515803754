import React, { useEffect, useState } from 'react';
import { ContentPageListDataDto } from '../../../../backend-types/src/content-pages/dto/content-page-list.dto';
import { contentPageService } from '../../service/ContentPageService';
import { LoadingIndicator } from '../../common/LoadingIndicator';
import { Divider, Fab, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { appLayoutService } from '../../layout/AppLayoutService';
import AddIcon from '@mui/icons-material/Add';
import ArticleIcon from '@mui/icons-material/Article';

function useContentPages(): [ boolean, ContentPageListDataDto[] ] {
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ contentPages, setContentPages ] = useState<ContentPageListDataDto[]>([]);

    useEffect((() => {
        contentPageService.list().then(response => {
            setContentPages(response.payload);
            setIsLoading(false);
        });
    }), []);

    return [ isLoading, contentPages ];
}

export function ContentPageListRoute() {
    const navigate = useNavigate();
    const [ isLoading, contentPages ] = useContentPages();

    if (isLoading) {
        return <LoadingIndicator/>;
    }

    appLayoutService.changeContext({
        title: 'Inhaltsseiten',
        floatingActionButton: (
            <Fab
                color="secondary"
                variant="extended"
                aria-label="Inhaltsseite anlegen"
                onClick={() => navigate('/content-page/create')}
            >
                <AddIcon/>
                Neu
            </Fab>
        ),
    });

    return (
        <React.Fragment>
            <List>
                {contentPages.map((contentPage, index) => (
                    <React.Fragment key={contentPage.id}>
                        {index !== 0 ? <Divider/> : null}
                        <ListItem>
                            <ListItemButton onClick={() => navigate(`/content-page/${contentPage.id}`)}>
                                <ListItemIcon>
                                    <ArticleIcon/>
                                </ListItemIcon>
                                <ListItemText primary={contentPage.title}/>
                            </ListItemButton>
                        </ListItem>
                    </React.Fragment>
                ))}
            </List>
        </React.Fragment>
    );
}
