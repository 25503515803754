import { AbstractApiService, UnaunthenticatedApiException } from './AbstractApiService';
import { TokenResponseDto, UserDetailResponseDto } from '../../../backend-types/types';
import { tokenService } from './TokenService';

export class UserIsNotAdminException extends UnaunthenticatedApiException {
    constructor() {
        super(null);
    }
}

class AuthenticationService extends AbstractApiService {

    isAuthenticated(): boolean {
        return tokenService.getToken() !== null;
    }

    async refreshToken(): Promise<void> {
        const response = await this.fetch('auth/refresh', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: tokenService.getToken()
            }),
        });

        const body = await response.json() as TokenResponseDto;
        const token = body.payload;

        if (!await this.authenticatedUserIsAdmin(token)) {
            this.logout();
            throw new UserIsNotAdminException();
        }

        tokenService.storeToken(token);
    }

    async login(email: string, password: string): Promise<void> {
        const response = await this.fetchUnauthenticated('auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({email: email, password: password}),
        });

        const body = await response.json() as TokenResponseDto;
        const token = body.payload;

        if (!await this.authenticatedUserIsAdmin(token)) {
            this.logout();
            throw new UserIsNotAdminException();
        }

        tokenService.storeToken(token);
    }

    logout(): void {
        tokenService.clearToken();
    }

    /**
     * Checks if the user for the given token is an admin.
     * Note: This request does manual authorization in order to prevent an invalid token from being
     *       written into storage.
     */
    private async authenticatedUserIsAdmin(token: string): Promise<boolean> {
        const response = await this.fetchUnauthenticated('user/current', {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        const body = await response.json() as UserDetailResponseDto;
        return body.payload.isAdmin;
    }

}


export const authenticationService = new AuthenticationService();
