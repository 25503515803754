import { Navigate, useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import { UserDetailDataDto } from '../../../backend-types/src/user/dto/user-detail-response.dto';
import { userService } from '../service/UserService';
import { CreateUserDto } from '../../../backend-types/src/user/dto/create-user.dto';
import { serializeError } from '../common/helpers';
import { UserForm } from '../user/UserForm';
import { Alert } from '@mui/lab';
import { appLayoutService } from '../layout/AppLayoutService';
import { LoadingIndicator } from '../common/LoadingIndicator';


export function UserEditRoute() {
    const navigate = useNavigate();
    const [ user, setUser ] = React.useState<UserDetailDataDto|null>(null);
    const [ loading, setLoading ] = React.useState<boolean>(false);
    const [ error, setError ] = React.useState<string|null>(null);
    const { id } = useParams();

    React.useEffect(() => {
        if (id) {
            userService.get(id).then(response => {
                setUser(response.payload);
            })
        }
    }, [ id ]);

    if (!id) {
        return <Navigate to='/user' />;
    }

    if (!user) {
        return <LoadingIndicator />;
    }

    appLayoutService.changeContext({ title: `Nutzer bearbeiten: ${user.firstName} ${user.lastName}` });

    const onSubmit = async (userData: CreateUserDto) => {
        setLoading(true);
        setError(null);
        try {
            const response = await userService.update(user.id, userData);
            navigate(`/user/${response.payload.id}`);
        } catch (e) {
            setError(await serializeError(e));
            setLoading(false);
        }
    }

    return (
        <React.Fragment>
            <UserForm
                user={user}
                onSubmit={onSubmit}
                submitText="Speichern"
                loading={loading}
            />

            {error ? <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert> : null }
        </React.Fragment>

    );
}
