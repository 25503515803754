

class TokenService {
    private static readonly TOKEN_KEY = 'TOKEN';

    getToken(): string|null {
        return window.localStorage.getItem(TokenService.TOKEN_KEY);
    }

    storeToken(token: string): void {
        window.localStorage.setItem(TokenService.TOKEN_KEY, token);
    }

    clearToken(): void {
        window.localStorage.removeItem(TokenService.TOKEN_KEY);
    }

    hasToken(): boolean {
        return this.getToken() !== null;
    }
}

export const tokenService = new TokenService();
