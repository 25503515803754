import { UserListDataDto } from '../../../backend-types/src/user/dto/user-list-response.dto';
import { ApiResponsePaginationDto } from '../../../backend-types/src/common/dto/api-response.dto';
import React, { useEffect, useState } from 'react';
import { userService } from '../service/UserService';
import { Alert, Avatar, Fab, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import { Pagination } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import { appLayoutService } from '../layout/AppLayoutService';
import { LoadingIndicator } from '../common/LoadingIndicator';

function useUsers(): [ boolean, UserListDataDto[], ApiResponsePaginationDto | null, (page: number) => void ] {
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ users, setUsers ] = useState<UserListDataDto[]>([]);
    const [ pagination, setPagination ] = useState<ApiResponsePaginationDto | null>(null);
    const [ page, setPage ] = useState<number>(1);

    useEffect(() => {
        userService.list(page).then(response => {
            setUsers(response.payload);
            setPagination(response.pagination);
            setIsLoading(false);
        });
    }, [ page ]);

    return [
        isLoading,
        users,
        pagination,
        setPage,
    ];
}

export function UserListRoute() {
    const [ loading, users, pagination, setPage ] = useUsers();
    const displayPagination = pagination !== null && pagination.lastPage > 1;
    const navigate = useNavigate();

    appLayoutService.changeContext({
        title: 'Nutzer',
        floatingActionButton: <UserListFab/>,
    });

    if (loading) {
        return <LoadingIndicator/>;
    }

    let paginationMarkup = null;
    if (displayPagination) {
        paginationMarkup = (
            <Pagination
                sx={{display: 'flex', justifyContent: 'center'}}
                count={pagination.lastPage}
                page={pagination.page}
                onChange={(e, page) => setPage(page)}
                color="primary"
                showFirstButton
                showLastButton
            />
        )
    }

    return (
        <React.Fragment>
            <List>
                {users.length === 0 ? <Alert severity="info">Keine Nutzer gefunden</Alert> : null}

                {users.map(user => (
                    <ListItem key={user.id}>
                        <ListItemButton onClick={() => navigate(`/user/${user.id}`)}>
                            <ListItemAvatar>
                                <Avatar/>
                            </ListItemAvatar>
                            <ListItemText primary={`${user.firstName} ${user.lastName}`}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>

            { paginationMarkup }
        </React.Fragment>
    );
}

function UserListFab() {
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <Fab
                sx={{ mr: 2 }}
                variant="extended"
                color="secondary"
                aria-label="Nutzer importieren"
                onClick={() => navigate('/user/import')}
            >
                <AddRoadIcon sx={{ mr: 1 }}/>
                Nutzer importieren
            </Fab>
            <Fab
                color="primary"
                variant="extended"
                aria-label="Neuen Nutzer anlegen"
                onClick={() => navigate('/user/create')}
            >
                <AddIcon/>
                Neu
            </Fab>
        </React.Fragment>
    );
}
