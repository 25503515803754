import React, { FunctionComponent, KeyboardEvent, useState } from 'react';
import { Alert, Box, TextField, Typography } from '@mui/material';
import { authenticationService, UserIsNotAdminException } from '../service/AuthenticationService';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import { serializeError } from '../common/helpers';
import { UnaunthenticatedApiException } from '../service/AbstractApiService';

interface LoginProps {
    navigateToRouteAfterLogin: string,
}

export const LoginRoute: FunctionComponent<LoginProps> = props => {
    const [ email, setEmail ] = useState<string>('');
    const [ password, setPassword ] = useState<string>('');
    const [ error, setError ] = useState<string|null>(null);
    const [ isLoggingIn, setIsLoggingIn ] = useState<boolean>(false);
    const navigate = useNavigate();

    const isButtonEnabled = email.length > 5
        && email.indexOf('@') !== -1
        && password.length > 5;

    const login = async () => {
        setError(null);
        setIsLoggingIn(true);

        try {
            await authenticationService.login(email, password);

            navigate(props.navigateToRouteAfterLogin);
        } catch (e) {
            if (e instanceof UserIsNotAdminException) {
                setError('Login is nicht möglich')
                setIsLoggingIn(false);
                return;
            } else if (e instanceof UnaunthenticatedApiException) {
                setError('Nutzername oder Passwort ist ungültig');
                setIsLoggingIn(false);
                return;
            }

            setError(await serializeError(e));
            setIsLoggingIn(false);
        }
    };

    return (
        <Box component="div" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h3">Anmelden</Typography>

            <Box
                component="form"
                onKeyPress={(e: KeyboardEvent<HTMLDivElement>) => e.key === 'Enter' && login()}
                sx={{
                    mt: 4,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    '& .MuiTextField-root, & .MuiButton-root': { m: 1, flex: 1 }
                }}
            >
                <TextField
                    label="E-Mail Adresse"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
                <TextField
                    label="Passwort"
                    type="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
                <LoadingButton
                    variant="contained"
                    loading={isLoggingIn}
                    disabled={!isButtonEnabled}
                    onClick={login}
                    size="large"
                >
                    Login
                </LoadingButton>
            </Box>

            {error ? <Alert severity="warning">{error}</Alert> : null}
        </Box>
    );
};
