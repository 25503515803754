import { useNavigate, useParams } from 'react-router-dom';
import { ContentPageDetailsDto } from '../../../../backend-types/src/content-pages/dto/content-page-details.dto';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { contentPageService } from '../../service/ContentPageService';
import { LoadingIndicator } from '../../common/LoadingIndicator';
import { Alert, Box, Button, Fab, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import { InlineBadge } from '../../common/InlineBadge';
import PublicIcon from '@mui/icons-material/Public';
import { appLayoutService } from '../../layout/AppLayoutService';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ModalSize, StyledModal } from '../../common/StyledModal';
import CloseIcon from '@mui/icons-material/Close';
import { serializeError } from '../../common/helpers';

export function useContentPage(id: string): [ boolean, ContentPageDetailsDto ] {
    const [ contentPage, setContentPage ] = useState<ContentPageDetailsDto>({
        id: '',
        title: '',
        content: '',
        public: false,
        systemName: null,
    });
    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    useEffect(() => {
        contentPageService.get(id).then(response => {
            setContentPage(response.payload);
            setIsLoading(false);
        })
    }, [ id ]);

    return [ isLoading, contentPage ];
}

interface ContentPageDetailFabProps {
    contentPage: ContentPageDetailsDto,
    onDeleteConfirmed: () => void;
}

const ContentPageDetailFab: FunctionComponent<ContentPageDetailFabProps> = (props) => {
    const navigate = useNavigate();
    const [ modalOpen, setModalOpen ] = useState<boolean>(false);

    return (
        <React.Fragment>
            <Fab color="secondary" variant="extended" aria-label="Inhaltsseite bearbeiten" onClick={() => navigate(`/content-page/${props.contentPage.id}/edit`)}>
                <EditIcon sx={{ mr: 1 }} />
                Bearbeiten
            </Fab>
            <Fab sx={{ ml: 2 }} color="primary" variant="extended" aria-label="Inhaltsseite löschen" onClick={() => setModalOpen(true)}>
                <DeleteIcon sx={{ mr: 1 }} />
                Löschen
            </Fab>
            <StyledModal
                title="Löschen bestätigen"
                isOpen={modalOpen}
                closeModal={() => setModalOpen(false)}
                size={ModalSize.Small}
                actions={(
                    <React.Fragment>
                        <Button variant="contained" startIcon={<CloseIcon />} onClick={() => setModalOpen(false)}>
                            Abbrechen
                        </Button>
                        <Button variant="outlined" endIcon={<DeleteIcon />} onClick={() => props.onDeleteConfirmed()}>
                            Löschen
                        </Button>
                    </React.Fragment>
                )}
            >
                Sind Sie sich sicher, dass Sie die Inhaltsseite &quot;{props.contentPage.title}&quot; löschen möchten?
            </StyledModal>
        </React.Fragment>
    );

}

export function ContentPageDetailRoute() {
    const navigate = useNavigate();
    const params = useParams();
    const id = String(params.id);
    const [ isLoading, contentPage ] = useContentPage(id);
    const [ isDeleting, setIsDeleting ] = useState<boolean>(false);
    const [ error, setError ] = useState<string|null>(null);

    if (isLoading || isDeleting) {
        return <LoadingIndicator />
    }

    const deleteContentPage = async () => {
        setIsDeleting(true);
        setError(null);

        try {
            await contentPageService.delete(contentPage.id);
            navigate('/content-page');
        } catch (e) {
            setIsDeleting(false);
            setError(await serializeError(e));
        }
    }

    appLayoutService.changeContext({
        title: `Inhaltsseite: ${contentPage.title}`,
        floatingActionButton: <ContentPageDetailFab
            contentPage={contentPage}
            onDeleteConfirmed={() => deleteContentPage()}
        />
    })

    let publicBadge = null;
    if (contentPage.public) {
        publicBadge = <InlineBadge borderColor={green[400]} backgroundColor={green[50]} icon={<PublicIcon />}>
            Public
        </InlineBadge>
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>

            {error && <Alert severity="error">{error}</Alert>}

            <Typography variant="h2">{contentPage.title}</Typography>

            {publicBadge}

            <div dangerouslySetInnerHTML={{ __html: contentPage.content }} />
        </Box>
    )
}
