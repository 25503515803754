import React, { FunctionComponent } from 'react';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';

export enum ModalSize {
    Small,
    Medium,
    Large,
}

const sizes: Record<ModalSize, {width: number; height: number}> = {
    [ModalSize.Small]: {width: 400, height: 400},
    [ModalSize.Medium]: { width: 600, height: 600 },
    [ModalSize.Large]: { width: 800, height: 800 },
};

interface StyledModalProps {
    title: string;
    isOpen: boolean;
    closeModal: () => void;
    size: ModalSize;
    actions?: React.ReactNode;
}

export const StyledModal: FunctionComponent<StyledModalProps> = props => {
    const size = sizes[props.size];

    let footer = null;
    if (props.actions) {
        footer = (
            <Box sx={{ flex: 0 }}>
                <Divider />
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    px: 4,
                    py: 2,
                }}>
                    {props.actions}
                </Box>
            </Box>
        )
    }

    return <Modal
        open={props.isOpen}
        onClose={() => props.closeModal()}
    >
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: size.width,
            maxHeight: size.height,
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
        }}>
            <Box sx={{
                flex: 0,
                bgcolor: 'primary.main',
                color: 'background.paper',
                px: 2,
                py: 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
                <Typography variant="h6">
                    {props.title}
                </Typography>
                <IconButton onClick={() => props.closeModal()}>
                    <CloseIcon color="inherit"/>
                </IconButton>
            </Box>
            <Box sx={{
                flex: 1,
                p: 4,
                overflow: 'auto',
            }}>
                {props.children}
            </Box>
            {footer}
        </Box>
    </Modal>;

};
