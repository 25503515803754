// See index.html
export interface UniFreiburgEnvType {
    apiBaseUrl: string,
    version: string,
    backgroundUrl: string,
    primaryColor: string,
    secondaryColor: string,
    appTitle: string,
    [name: string]: string,
}

declare global {
    interface Window {
        uniFreiburgEnv: UniFreiburgEnvType,
    }
}

/**
 * The environment can be passed using 2 different ways:
 *
 * 1. As environment variables during build time
 * 2. As values from `index.html`. These include default values but also placeholders that
 *    are replaced by an entrypoint script while starting the container.
 */
function buildTimeOverride(
    name: string,
    buildTimeEnvVar: unknown,
    defaultValue: string
): string {
    if (typeof buildTimeEnvVar === 'string' && buildTimeEnvVar !== '') {
        console.log(`Using ${name}=${buildTimeEnvVar} from webpack build.`);
        return buildTimeEnvVar;
    }

    console.log(`Using ${name}=${defaultValue} from window.uniFreiburgEnv.`);
    return defaultValue;
}

export const UniFreiburgEnv: UniFreiburgEnvType = Object.freeze({
    apiBaseUrl: buildTimeOverride('apiBaseUrl', process.env.WEBPACK_BUILD_API_BASE_URL, window.uniFreiburgEnv.apiBaseUrl),
    backgroundUrl: buildTimeOverride('backgroundUrl', process.env.WEBPACK_BUILD_BACKGROUND_URL, window.uniFreiburgEnv.backgroundUrl),
    version: buildTimeOverride('version', process.env.WEBPACK_BUILD_APP_VERSION, window.uniFreiburgEnv.version),
    primaryColor: buildTimeOverride('primaryColor', process.env.WEBPACK_BUILD_PRIMARY_COLOR, window.uniFreiburgEnv.primaryColor),
    secondaryColor: buildTimeOverride('secondaryColor', process.env.WEBPACK_BUILD_SECONDARY_COLOR, window.uniFreiburgEnv.secondaryColor),
    appTitle: buildTimeOverride('appTitle', process.env.WEBPACK_BUILD_APP_TITLE, window.uniFreiburgEnv.appTitle),
});
