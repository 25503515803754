import {AbstractApiService} from "./AbstractApiService";
import {ThemeDataDto, ThemeResponseDto} from "../../../backend-types/src/theme/dto/theme-response.dto";

class ThemeService extends AbstractApiService {

    async getDefaultTheme(): Promise<ThemeResponseDto> {
        const response = await this.fetch('theme');
        return await response.json() as ThemeResponseDto;
    }

    async updateDefaultTheme(theme: ThemeDataDto): Promise<void> {
        await this.fetch('theme', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(theme),
        })

        return;
    }

    async uploadFile(file: File): Promise<void> {
        const data = new FormData();
        data.append('image', file);

        await this.fetch('theme/background', {
            method: 'PATCH',
            headers: {},
            body: data
        })

        return;
    }

}

export const themeService = new ThemeService();