import './ContentPageForm.css';
import { ContentPageDetailsDto } from '../../../../backend-types/src/content-pages/dto/content-page-details.dto';
import React, { FunctionComponent, useState } from 'react';
import { FormControl, FormControlLabel, MenuItem, Select, Stack, Switch, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import RichTextEditor, { EditorValue } from 'react-rte';
import 'draft-js/dist/Draft.css';
import {
    ContentPageModificationDto
} from '../../../../backend-types/src/content-pages/dto/content-page-modification.dto';

const SYSTEM_NAME_NONE_PLACEHOLDER = '__none__';
const SYSTEM_NAME_IMPRINT = 'imprint';
const SYSTEM_NAME_PRIVACY_POLICY = 'privacy_policy';
const publicSystemPages = [ SYSTEM_NAME_IMPRINT, SYSTEM_NAME_PRIVACY_POLICY ];

type ContentPageEditDto = Omit<ContentPageDetailsDto, "id">;

interface ContentPageFormProps {
    contentPage: ContentPageEditDto,
    onSubmit: (contentPage: ContentPageModificationDto) => void,
    submitText: string,
    loading?: boolean,
}

export const ContentPageForm: FunctionComponent<ContentPageFormProps> = props => {
    const [ enableButton, setEnableButton ] = useState<boolean | null>(null);
    const [ contentPage, setContentPage ] = useState<ContentPageEditDto>({...props.contentPage});
    const [ rte, setRte ] = useState<EditorValue>(() => RichTextEditor.createValueFromString(props.contentPage.content, 'html'));

    const updateButtonState = (contentPage: ContentPageEditDto) => {
        setEnableButton(contentPage.title.length > 5 && contentPage.content.length > 20);
    };

    const updatePage = (partialContentPage: Partial<ContentPageEditDto>) => {
        if (partialContentPage.systemName) {
            if (partialContentPage.systemName === SYSTEM_NAME_NONE_PLACEHOLDER) {
                partialContentPage.systemName = null;
            }

            partialContentPage.public = publicSystemPages.includes(String(partialContentPage.systemName));
        }

        setContentPage({...contentPage, ...partialContentPage});
        updateButtonState({...contentPage, ...partialContentPage});
    };

    // Set initial button state depending on contentPage
    if (enableButton === null) {
        updateButtonState(contentPage);
    }

    return (
        <Stack spacing={2}>
            <TextField
                label="Titel"
                value={contentPage.title}
                onChange={e => updatePage({title: e.target.value})}
                disabled={props.loading}
            />

            <Select
                value={contentPage.systemName ?? SYSTEM_NAME_NONE_PLACEHOLDER}
                onChange={e => updatePage({systemName: e.target.value})}
                label="Systemrolle"
                defaultValue={SYSTEM_NAME_NONE_PLACEHOLDER}
            >
                <MenuItem value={SYSTEM_NAME_NONE_PLACEHOLDER}>-</MenuItem>
                <MenuItem value={SYSTEM_NAME_IMPRINT}>Impressum</MenuItem>
                <MenuItem value={SYSTEM_NAME_PRIVACY_POLICY}>Datenschutzerklärung</MenuItem>
            </Select>

            <FormControl>
                <FormControlLabel
                    control={
                        <Switch
                            checked={contentPage.public}
                            disabled={true}
                            onChange={e => updatePage({public: e.target.checked})}
                        />
                    }
                    label="Öffentlich Verfügbar (z.b. für Impressum)"
                />
            </FormControl>
            <RichTextEditor
                value={rte}
                onChange={rte => {
                    setRte(rte);
                    updatePage({content: rte.toString('html')});
                }}
                disabled={props.loading}
                toolbarConfig={{
                    display: [ 'INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS' ],
                    INLINE_STYLE_BUTTONS: [
                        {label: 'Fett', style: 'BOLD', className: 'rte-button'},
                        {label: 'Kursiv', style: 'ITALIC', className: 'rte-button'},
                        {label: 'Unterstrichen', style: 'UNDERLINE', className: 'rte-button'},
                    ],
                    BLOCK_TYPE_BUTTONS: [
                        {label: 'Liste', style: 'unordered-list-item', className: 'rte-button'},
                        {label: 'Aufzählung', style: 'ordered-list-item', className: 'rte-button'},
                    ],
                    BLOCK_TYPE_DROPDOWN: [],
                }}
                editorStyle={{
                    fontFamily: 'Roboto, sans-serif',
                    margin: '1em',
                }}
            />


            <LoadingButton
                variant="contained"
                onClick={() => props.onSubmit(contentPage)}
                loading={props.loading}
                disabled={!enableButton}
            >
                {props.submitText}
            </LoadingButton>
        </Stack>
    );
};
