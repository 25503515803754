import { createTheme } from '@mui/material';
import tinycolor from 'tinycolor2';
import { UniFreiburgEnv, UniFreiburgEnvType } from '../service/UniFreiburgEnv';

declare global {
    interface Window {
        _uniFreiburgEnvFallback: UniFreiburgEnvType,
    }
}

const lightnessSpread = 0.25;
let primary = tinycolor(UniFreiburgEnv.primaryColor);
if (!primary.isValid()) {
    console.warn(`Invalid primary color: ${UniFreiburgEnv.primaryColor}. Falling back to ${window._uniFreiburgEnvFallback.primaryColor}`);
    primary = tinycolor(window._uniFreiburgEnvFallback.primaryColor);
}

let secondary = tinycolor(UniFreiburgEnv.secondaryColor);
if (!secondary.isValid()) {
    console.warn(`Invalid secondary color: ${UniFreiburgEnv.secondaryColor}. Falling back to ${window._uniFreiburgEnvFallback.secondaryColor}`);
    secondary = tinycolor(window._uniFreiburgEnvFallback.secondaryColor);
}

export const uniFreiburgTheme = createTheme({
    palette: {
        // Uni Freiburg primary color #b5142f
        // Rest of shades generated
        primary: {
            light: primary.lighten(lightnessSpread).toHexString(),
            main: primary.toHexString(),
            dark: primary.darken(lightnessSpread).toHexString(),
        },

        // Uni Freiburg primary color #254aa5
        // Rest of shades generated
        secondary: {
            light: secondary.lighten(lightnessSpread).toHexString(),
            main: secondary.toHexString(),
            dark: secondary.darken(lightnessSpread).toHexString(),
        },
    }
});
