import { UniFreiburgEnv } from './UniFreiburgEnv';
import { tokenService } from './TokenService';

export class ApiException extends Error {
    constructor(
        message: string,
        readonly status: number,
        readonly response: Response|null,
    ) {
        super(message);
    }
}

export class UnaunthenticatedApiException extends ApiException {
    constructor(response: Response|null) {
        super('Unauthenticated', 401, response);
    }
}

export abstract class AbstractApiService {
    protected get baseUrl(): string {
        return UniFreiburgEnv.apiBaseUrl;
    }

    protected fetch(endpoint: string, fetchOptions?: RequestInit): Promise<Response> {
        fetchOptions = fetchOptions || {};

        const headers = new Headers(fetchOptions.headers);
        if (!headers.has('Authorization')) {
            const token = tokenService.getToken();
            if (token !== null) {
                headers.append('Authorization', `Bearer ${token}`);
            }
        }
        fetchOptions.headers = headers;

        return this.fetchUnauthenticated(endpoint, fetchOptions);
    }

    /**
     * Make an API request to the given endpoint without credentials
     * @param endpoint
     * @param fetchOptions
     * @protected
     */
    protected async fetchUnauthenticated(endpoint: string, fetchOptions?: RequestInit): Promise<Response> {
        fetchOptions = fetchOptions || {};

        const url = `${this.baseUrl}/${endpoint}`;

        fetchOptions.method = fetchOptions.method || 'GET';
        fetchOptions.body = fetchOptions.body || null;
        fetchOptions.mode = fetchOptions.mode || 'cors';

        const response = await fetch(url, fetchOptions);

        if (response.status >= 400) {
            throw this.apiException(response);
        }

        return response;
    }

    private apiException(response: Response): ApiException {
        switch (response.status) {
        case 401:
            return new UnaunthenticatedApiException(response);
        default:
            return new ApiException(
                `${response.status} ${response.statusText}`,
                response.status,
                response,
            );
        }
    }
}
