import React, { FunctionComponent } from 'react';
import { UserDetailDataDto } from '../../../backend-types/src/user/dto/user-detail-response.dto';
import { FormControlLabel, FormGroup, Stack, Switch, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { CreateUserDto } from '../../../backend-types/src/user/dto/create-user.dto';

export type UserFormData = Omit<UserDetailDataDto, 'id' | 'createdAt' | 'updatedAt' | 'onlineStatus'>;

interface UserFormProps {
    user: UserFormData,
    onSubmit: (user: CreateUserDto) => void,
    submitText: string,
    loading?: boolean,
    additionalCheckbox?: React.ReactNode,
}

export const UserForm: FunctionComponent<UserFormProps> = props => {
    const [ enableButton, setEnableButton ] = React.useState<boolean | null>(null);
    const [ user, setUser ] = React.useState<CreateUserDto>({...props.user });
    const updateButtonState = (user: CreateUserDto) => {
        setEnableButton(
            user.firstName.length >= 2
            && user.lastName.length >= 2
            && user.email.length >= 5
            && user.email.indexOf('@') !== -1,
        );
    };
    const updateUser = (partialUser: Partial<CreateUserDto>) => {
        updateButtonState({...user, ...partialUser});
        setUser({...user, ...partialUser});
    };

    // Set initial state of the button depending on the user
    if (enableButton === null) {
        updateButtonState(user);
    }

    return (
        <Stack spacing={2}>
            <TextField
                type="email"
                label="E-Mail Adresse"
                value={user.email}
                onChange={(e) => updateUser({email: e.target.value})}
                disabled={props.loading}
            />
            <TextField
                label="Vorname"
                value={user.firstName}
                onChange={(e) => updateUser({firstName: e.target.value})}
                disabled={props.loading}
            />
            <TextField
                label="Nachname"
                value={user.lastName}
                onChange={(e) => updateUser({lastName: e.target.value})}
                disabled={props.loading}
            />
            <TextField
                label="Biografie"
                value={user.biography}
                onChange={(e) => updateUser({biography: e.target.value})}
                multiline
                rows={4}
                disabled={props.loading}
            />
            <Stack direction="row" spacing={2}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={user.isEnabled}
                                onChange={(e) => updateUser({isEnabled: e.target.checked})}
                            />
                        }
                        label="Aktiv"
                    />
                </FormGroup>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={user.isAdmin}
                                onChange={(e) => updateUser({isAdmin: e.target.checked})}
                            />
                        }
                        label="Admin"
                        disabled={props.loading}
                    />
                </FormGroup>
                {props.additionalCheckbox}
            </Stack>

            <LoadingButton
                variant="contained"
                onClick={() => props.onSubmit(user)}
                loading={props.loading}
                disabled={!enableButton}
            >
                {props.submitText}
            </LoadingButton>
        </Stack>
    );
};
