import './service/AbstractApiService';
import React, { useState } from 'react';
import './App.css';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { HomeRoute } from './routes/HomeRoute';
import { AppLayout } from './layout/AppLayout';
import { LoginRoute } from './routes/LoginRoute';
import { authenticationService } from './service/AuthenticationService';
import { LoginLayout } from './layout/LoginLayout';
import { UserListRoute } from './routes/UserListRoute';
import { UserDetailRoute } from './routes/UserDetailRoute';
import { Error404Route } from './routes/Error404Route';
import { UserCreateRoute } from './routes/UserCreateRoute';
import { UserEditRoute } from './routes/UserEditRoute';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import ArticleIcon from '@mui/icons-material/Article';
import { UserImportRoute } from './routes/UserImportRoute';
import { ContentPageListRoute } from './routes/content-page/ContentPageListRoute';
import { ContentPageDetailRoute } from './routes/content-page/ContentPageDetailRoute';
import { ContentPageEditRoute } from './routes/content-page/ContentPageEditRoute';
import { ContentPageCreateRoute } from './routes/content-page/ContentPageCreateRoute';
import {ThemeEditRoute} from "./routes/ThemeEditRoute";
import { GdprRoute } from "./routes/GdprRoute";

export function useIsAuthenticated(): boolean {
    const [ isAuthenticated, setIsAuthenticated ] = useState<boolean>(authenticationService.isAuthenticated());
    React.useEffect(() => setIsAuthenticated(authenticationService.isAuthenticated()));
    return isAuthenticated;
}

/**
 * Refreshes the token once. The returned state value is `true` if the token has been refreshed
 * and `false` if the token has not been refreshed. Logs the user out and redirects to the login
 * page if the token cannot be refreshed.
 */
export function useRefreshedToken(): boolean {
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();
    const [ hasRefreshed, setHasRefreshed ] = useState<boolean>(false);

    React.useEffect(() => {
        if (!isAuthenticated) {
            return;
        }

        authenticationService.refreshToken()
            .then(() => setHasRefreshed(true))
            .catch(e => {
                console.log('Failed to refresh token', e);
                authenticationService.logout();
                navigate('/');
            });
    }, [ isAuthenticated ]);

    return hasRefreshed;
}

export function App() {
    const isAuthenticated = useIsAuthenticated();
    const location = useLocation();

    // Redirect non-authenticated users to the login page
    if (!isAuthenticated && location.pathname !== '/login' && location.pathname !== '/gdpr') {
        return <Navigate to="/login"/>;
    }

    // Redirect authenticated users to the home page if they open the login page
    if (isAuthenticated && location.pathname === '/login') {
        return <Navigate to="/home"/>;
    }

    const routes = {
        '/home': HomeRoute,
        '/user': UserListRoute,
        '/user/:id': UserDetailRoute,
        '/user/:id/edit': UserEditRoute,
        '/user/create': UserCreateRoute,
        '/user/import': UserImportRoute,
        '/content-page': ContentPageListRoute,
        '/content-page/:id': ContentPageDetailRoute,
        '/content-page/:id/edit': ContentPageEditRoute,
        '/content-page/create': ContentPageCreateRoute,
        '/theme': ThemeEditRoute,
    };

    const navigation = {
        '/home': {
            title: 'Startseite',
            icon: <DashboardIcon/>,
        },
        '/user': {
            title: 'Nutzer',
            icon: <PersonIcon/>,
        },
        '/content-page': {
            title: 'Content Seiten',
            icon: <ArticleIcon/>,
        },
        '/theme': {
            title: 'Client Theme',
            icon: <DashboardIcon/>,
        },
    };

    return (
        <Routes>
            <Route path="/" element={<Navigate to="/login"/>}/>
            <Route path="/login" element={<LoginLayout><LoginRoute navigateToRouteAfterLogin="/home"/></LoginLayout>}/>
            <Route path="/gdpr" element={<LoginLayout title={"DSGVO"}><GdprRoute/></LoginLayout>}/>
            {Object.entries(routes).map(([ path, Component ]) => {
                return <Route
                    key={path}
                    path={path}
                    element={
                        <AppLayout navigation={navigation}>
                            <Component/>
                        </AppLayout>
                    }
                />;
            })}
            <Route path="*" element={<AppLayout navigation={navigation}><Error404Route/></AppLayout>}/>
        </Routes>
    );
}
