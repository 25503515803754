import React, { FunctionComponent, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';

/**
 * A loading indicator that is only displayed if loading longer than 300ms.
 */
export const LoadingIndicator: FunctionComponent = () => {
    const [ show, setShow ] = useState<boolean>(false);

    React.useEffect(() => {
        const timer = setTimeout(() => setShow(true), 300);
        return () => clearTimeout(timer);
    }, []);

    if (!show) {
        return null;
    }

    return (
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px'}}>
            <CircularProgress/>
        </Box>
    );
};
