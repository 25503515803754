import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { appLayoutService } from '../../layout/AppLayoutService';
import {
    ContentPageModificationDto,
} from '../../../../backend-types/src/content-pages/dto/content-page-modification.dto';
import { contentPageService } from '../../service/ContentPageService';
import { serializeError } from '../../common/helpers';
import { ContentPageForm } from './ContentPageForm';
import { Alert } from '@mui/lab';


export function ContentPageCreateRoute() {
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ error, setError ] = useState<string | null>(null);
    const navigate = useNavigate();

    appLayoutService.changeContext({title: 'Inhaltsseite anlegen'});

    const onSubmit = async (contentPage: ContentPageModificationDto) => {
        setLoading(true);
        setError(null);
        try {
            const response = await contentPageService.create(contentPage);
            navigate(`/content-page/${response.payload.id}`);
        } catch (e) {
            setError(await serializeError(e));
            setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <ContentPageForm
                contentPage={{
                    title: '',
                    content: '',
                    systemName: null,
                    public: false,
                }}
                onSubmit={onSubmit}
                submitText="Erstellen"
                loading={loading}
            />

            {error ? <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert> : null }
        </React.Fragment>
    );
}
