import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { Alert, AlertTitle } from '@mui/lab';
import { useNavigate } from 'react-router-dom';

export function Error404Route() {
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <Typography sx={{ mb: 2 }} variant="h1">Nicht gefunden</Typography>
            <Alert severity="error">
                <AlertTitle>
                    Die Seite, die Sie suchen, konnte nicht gefunden werden.
                </AlertTitle>

                <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={() => navigate(-1)}>
                        Zurück zur letzten Seite
                    </Button>
                    <Button variant="contained" onClick={() => navigate('/home')}>
                        Zurück zur Startseite
                    </Button>
                </Stack>

            </Alert>
        </React.Fragment>
    );
}
