import { ApiException } from '../service/AbstractApiService';

export async function serializeError(error: any): Promise<string> {
    if (error instanceof ApiException && error.response) {
        try {
            const body = await error.response.json() as {[key: string]: any};
            const messages = [];
            if (typeof body.error === 'string') {
                messages.push(body.error);
            }
            if (typeof body.message === 'string') {
                messages.push(body.message);
            }
            if (body.message instanceof Array) {
                for (const msg of body.message) {
                    messages.push(msg);
                }
            }
            if (body.validationErrors) {
                for (const errors of Object.values(body.validationErrors as Record<string, {message: string}[]>)) {
                    for (const error of errors) {
                        messages.push(error.message);
                    }
                }
            }
            if (messages.length > 0) {
                return messages.join(', ');
            }
        } catch (e) {
            // Do nothing, handle the rest below.
        }
    }
    if (error instanceof Error) {
        return error.message;
    }
    if (typeof error === 'string') {
        return error;
    }
    console.error(error);
    return 'Ein unbekannter Fehler ist aufgetreten';
}
