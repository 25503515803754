import React, { useEffect, useState } from 'react';
import { UserDetailDataDto } from '../../../backend-types/src/user/dto/user-detail-response.dto';
import { userService } from '../service/UserService';
import { appLayoutService } from '../layout/AppLayoutService';
import { LoadingIndicator } from '../common/LoadingIndicator';

export function HomeRoute() {
    appLayoutService.changeContext({ title: 'Startseite' })

    const [ user, setUser ] = useState<UserDetailDataDto | null>(null);
    useEffect(() => { userService.getCurrent().then(response => setUser(response.payload)); }, []);

    if (user === null) {
        return <LoadingIndicator />;
    }

    return (
        <div>
            Hello {user.firstName} {user.lastName}
        </div>
    );
}
