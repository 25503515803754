import React, { FunctionComponent } from 'react';
import { AppBar, Box, Card, CardContent, Container, Toolbar, Typography } from '@mui/material';
import uniLogo from './uni-logo.png';
import { DebugInformation } from './DebugInformation';
import { UniFreiburgEnv } from '../service/UniFreiburgEnv';

interface LoginLayoutProps {
    title?: string,
}

export const LoginLayout: FunctionComponent<LoginLayoutProps> = props => {
    let backgroundImage = uniLogo;
    if (UniFreiburgEnv.backgroundUrl) {
        backgroundImage = UniFreiburgEnv.backgroundUrl;
    }
    let title = 'Login';
    if (props.title) {
        title = props.title;
    }

    return (
        <Box component="div" sx={{ background: '#fafafa', width: '100vw', height: '100vh', overflow: 'hidden'}}>
            <AppBar position="relative">
                <Toolbar>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{flexGrow: 1}}
                    >
                        { title }
                    </Typography>
                </Toolbar>
            </AppBar>

            <Box
                component="main"
                sx={{marginTop: '75px', flexGrow: 1, p: 3, width: {sm: '100%'}}}
            >
                <Container
                    maxWidth="xs"
                    sx={{
                        mt: 6,
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'auto',
                        height: '100%',
                    }}
                >
                    <Card sx={{ zIndex: 1 }}>
                        <CardContent>
                            {props.children}
                        </CardContent>
                    </Card>
                    <img
                        src={backgroundImage}
                        style={{
                            position: 'absolute',
                            bottom: '0',
                            right: '0',
                            width: '75vw',
                            opacity: 0.4,
                            zIndex: 0,
                        }}
                    />
                    <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
                        <DebugInformation />
                    </Box>
                </Container>
            </Box>
        </Box>
    );
};
