import { Navigate, useNavigate, useParams } from 'react-router-dom';
import React, { FunctionComponent } from 'react';
import { userService } from '../service/UserService';
import { UserDetailDataDto } from '../../../backend-types/src/user/dto/user-detail-response.dto';
import {
    Avatar,
    Box,
    Button,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import CloseIcon from '@mui/icons-material/Close';
import { InlineBadge } from '../common/InlineBadge';
import { blue, red } from '@mui/material/colors';
import { appLayoutService } from '../layout/AppLayoutService';
import EditIcon from '@mui/icons-material/Edit';
import { LoadingIndicator } from '../common/LoadingIndicator';
import format from 'date-fns/format';
import LockResetIcon from '@mui/icons-material/LockReset';
import { ModalSize, StyledModal } from '../common/StyledModal';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Alert } from '@mui/lab';

function formatDate(date: string|null): string {
    if (!date) {
        return '';
    }
    return format(new Date(date), 'dd.MM.yyyy HH:mm:ss');
}

export function UserDetailRoute() {
    const [ user, setUser ] = React.useState<UserDetailDataDto|null>(null);
    const [ avatar, setAvatar ] = React.useState<string|null>(null);
    const [ passwordResetSuccess, setPasswordResetSuccess ] = React.useState<boolean|null>(null);
    const [ showResetPasswordModal, setShowResetPasswordModal ] = React.useState<boolean>(false);
    const [ welcomeMailSuccess, setWelcomeMailSuccess ] = React.useState<boolean|null>(null);
    const [ userDeleteSuccess, setUserDeleteSuccess ] = React.useState<boolean|null>(null);
    const [ showWelcomeMailModal, setShowWelcomeMailModal ] = React.useState<boolean>(false);
    const [ showDeleteUserModal, setShowDeleteUserModal ] = React.useState<boolean>(false);
    const { id } = useParams();

    const navigate = useNavigate();

    React.useEffect(() => {
        if (id) {
            userService.get(id).then(response => {
                setUser(response.payload);
                setAvatar(response._links.avatar);
            })
        }
    }, [ id ]);

    if (!id) {
        return <Navigate to='/user' />;
    }

    if (!user) {
        return <LoadingIndicator />;
    }

    appLayoutService.changeContext({
        title: `${user.firstName} ${user.lastName}`,
        floatingActionButton: (
            <UserDetailFab
                user={user}
                onPasswordResetClick={() => setShowResetPasswordModal(true)}
                onWelcomeMailClick={() => setShowWelcomeMailModal(true)}
                onDeleteClick={() => setShowDeleteUserModal(true)}
            />
        ),
    });

    let adminBadge = null;
    if (user.isAdmin) {
        adminBadge = (<InlineBadge borderColor={blue[400]} backgroundColor={blue[50]} icon={<SupervisorAccountIcon />}>
            Administrator
        </InlineBadge>);
    }

    let disabledBadge = null;
    if (!user.isEnabled) {
        disabledBadge = (
            <InlineBadge borderColor={red[400]} backgroundColor={red[50]} icon={<CloseIcon />}>
                Inaktiv
            </InlineBadge>
        )
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
            { avatar ? <Avatar sx={{ width: '100px', height: '100px' }} src={avatar} /> : null}

            <Typography variant="h2">{user.firstName} {user.lastName}</Typography>

            {disabledBadge}
            {adminBadge}

            { user.biography ? <Typography variant="body1">{user.biography}</Typography> : null }

            <TableContainer>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th">E-Mail Adresse</TableCell>
                            <TableCell>{user.email}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th">Erstellungszeitpunkt</TableCell>
                            <TableCell>{formatDate(user.createdAt)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th">Zuletzt aktualisiert</TableCell>
                            <TableCell>{formatDate(user.updatedAt)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <StyledModal
                title="Passwort zurücksetzen"
                isOpen={showResetPasswordModal}
                closeModal={() => setShowResetPasswordModal(false)}
                size={ModalSize.Medium}
            >
                <Typography>Sendet dem Nutzer eine E-Mail mit einem Code, um das Passwort zurückzusetzen.</Typography>
                {passwordResetSuccess === true ? <Alert severity="success">E-Mail wurde versendet.</Alert> : null}
                {passwordResetSuccess === false ? <Alert severity="error">Beim Versenden der E-Mail ist ein Fehler aufgetreten.</Alert> : null}
                <Button
                    variant="contained"
                    sx={{ mt: 4 }}
                    onClick={() => {
                        userService.requestPasswordReset(user.email).then((success) => {
                            setPasswordResetSuccess(success);
                        });
                    }}
                >
                    Passwort zurücksetzen
                </Button>
            </StyledModal>
            <StyledModal
                title="Willkommensmail senden"
                isOpen={showWelcomeMailModal}
                closeModal={() => setShowWelcomeMailModal(false)}
                size={ModalSize.Medium}
            >
                <Typography>Sendet dem Nutzer eine Willkommensmail mit einem Code, um das eigene Konto zu aktivieren</Typography>
                {welcomeMailSuccess === true ? <Alert sx={{ mt: 2 }} severity="success">E-Mail wurde versendet.</Alert> : null}
                {welcomeMailSuccess === false ? <Alert sx={{ mt: 2 }} severity="error">Beim Versenden der E-Mail ist ein Fehler aufgetreten.</Alert> : null}
                <Button
                    variant="contained"
                    sx={{ mt: 4 }}
                    onClick={() => {
                        userService.sendWelcomeMail([ user.id ]).then((success) => {
                            setWelcomeMailSuccess(success[user.id]);
                        });
                    }}
                >
                    Willkommensmail senden
                </Button>
            </StyledModal>
            <StyledModal
                title="Nutzer löschen"
                isOpen={showDeleteUserModal}
                closeModal={() => {
                    setShowDeleteUserModal(false);
                    if (userDeleteSuccess) {
                        navigate('/user');
                    }
                }}
                size={ModalSize.Medium}
            >
                <Typography>Löscht den Nutzer/die Nutzerin und alle zugehörigen Daten wie Chatnachrichten, Videochats etc.</Typography>
                {userDeleteSuccess === true ? <Alert sx={{ mt: 2 }} severity="success">Nutzer/Nutzerin wurde erfolgreich gelöscht.</Alert> : null}
                {userDeleteSuccess === false ? <Alert sx={{ mt: 2 }} severity="error">Beim Löschen des Nutzers/der Nutzerin ist ein Fehler aufgetreten.</Alert> : null}
                <Button
                    variant="contained"
                    sx={{ mt: 4 }}
                    onClick={() => {
                        userService.delete(user.id).then((response) => {
                            setUserDeleteSuccess(response.payload.success);
                        });
                    }}
                >
                    Nutzer/Nutzerin löschen
                </Button>
            </StyledModal>
        </Box>
    )
}

interface UserDetailFabProps {
    user: UserDetailDataDto;
    onPasswordResetClick: () => void;
    onWelcomeMailClick: () => void;
    onDeleteClick: () => void;
}
const UserDetailFab: FunctionComponent<UserDetailFabProps> = (props) => {
    const navigate = useNavigate();

    const fabs = [
        <Fab key='edit' color="secondary" variant="extended" aria-label="Nutzer bearbeiten" onClick={() => navigate(`/user/${props.user.id}/edit`)}>
            <EditIcon sx={{ mr: 1 }} />
            Bearbeiten
        </Fab>
    ];

    if (props.user.isEnabled) {
        fabs.push(
            <Fab sx={{ ml: 2 }} color="primary" variant="extended" aria-label="Passwort zurücksetzen" onClick={props.onPasswordResetClick}>
                <LockResetIcon sx={{ mr: 1 }} />
                Passwort zurücksetzen
            </Fab>
        )
    } else {
        fabs.push(
            <Fab sx={{ ml: 2 }} color="primary" variant="extended" aria-label="Passwort zurücksetzen" onClick={props.onWelcomeMailClick}>
                <AlternateEmailIcon sx={{ mr: 1 }} />
                Wilkommensnachricht senden
            </Fab>
        )
    }

    fabs.push(
        <Fab sx={{ ml: 2 }} color="primary" variant="extended" aria-label="Nutzer/Nutzerin löschen" onClick={props.onDeleteClick}>
            <DeleteForeverIcon sx={{ mr: 1 }} />
            Nutzer/Nutzerin löschen
        </Fab>
    );


    return <React.Fragment>{fabs}</React.Fragment>;
}
