import React, {Dispatch, FunctionComponent, SetStateAction, useEffect, useState} from 'react';
import { ThemeDataDto } from "../../../backend-types/src/theme/dto/theme-response.dto";
import { themeService } from "../service/ThemeService";
import { LoadingIndicator } from "../common/LoadingIndicator";
import { Button, Stack, styled, TextField } from "@mui/material";
import { serializeError } from "../common/helpers";
import { Alert } from "@mui/lab";
import { UniFreiburgEnv } from "../service/UniFreiburgEnv";

const Input = styled('input')({
    display: 'none',
});

export function ThemeEditRoute() {

    const [ isLoading, setIsLoading, theme, setTheme ] = useTheme();
    const [ error, setError ] = React.useState<string|null>(null);
    const [ file, setFile ] = useState<File | null>(null);

    React.useEffect(() => {
        if (!file) {
            return;
        }

        setError(null);
        setIsLoading(true);
        themeService.uploadFile(file)
            .then(() => {
                setIsLoading(false);
            })
            .catch(async err => {
                setError(await serializeError(err));
                setIsLoading(false);
            })
    }, [file]);

    if (isLoading) {
        return <LoadingIndicator />
    }

    const updateTheme = (partialThemeData: Partial<ThemeDataDto>) => {
        setTheme({...theme, ...partialThemeData});
    }

    const isColor = (col: string): boolean => {
        return (/^#[0-9,a-f]{6}$/.test(col));
    }

    const themeHasOnlyColors = (theme: ThemeDataDto): boolean => {
        return isColor(theme.colorPrimary) && isColor(theme.colorPrimaryAlt) && isColor(theme.colorSecondary) && isColor(theme.colorSecondaryAlt);
    }

    const updateColors = async () => {
        setIsLoading(true);
        setError(null);
        try {
            await themeService.updateDefaultTheme(theme);
            setIsLoading(false);
        } catch (e) {
            setError(await serializeError(e));
            setIsLoading(false);
        }
    };

    return (
        <React.Fragment>
            {error ? <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert> : null }
            <h1>Theme Editor</h1>

            <Stack spacing={2}>
                <h2>Edit Colors</h2>
                <TextField
                    label="Primary Color"
                    value={theme.colorPrimary}
                    onChange={e => updateTheme({colorPrimary: e.target.value})}
                    error={!isColor(theme.colorPrimary)}
                />
                <TextField
                    label="Primary Color Alternative"
                    value={theme.colorPrimaryAlt}
                    onChange={e => updateTheme({colorPrimaryAlt: e.target.value})}
                    error={!isColor(theme.colorPrimaryAlt)}
                />
                <TextField
                    label="Secondary Color"
                    value={theme.colorSecondary}
                    onChange={e => updateTheme({colorSecondary: e.target.value})}
                    error={!isColor(theme.colorSecondary)}
                />
                <TextField
                    label="Secondary Color Alternative"
                    value={theme.colorSecondaryAlt}
                    onChange={e => updateTheme({colorSecondaryAlt: e.target.value})}
                    error={!isColor(theme.colorSecondaryAlt)}
                />
                <Button disabled={!themeHasOnlyColors(theme)} onClick={updateColors}>Save</Button>
            </Stack>
            <Stack spacing={2}>
                <h2>Edit Background Image</h2>
                <ImageFileSelector onFileSelection={setFile} />
                <img src={`${UniFreiburgEnv.apiBaseUrl}/theme/background`} width={200} alt={'Current theme background'} />
            </Stack>
        </React.Fragment>
    )
}

function useTheme(): [ boolean, Dispatch<SetStateAction<boolean>>, ThemeDataDto, Dispatch<SetStateAction<ThemeDataDto>> ] {
    const [ theme, setTheme] = useState<ThemeDataDto>({
        id: '',
        background: '',
        colorPrimary: '',
        colorPrimaryAlt: '',
        colorSecondary: '',
        colorSecondaryAlt: '',
    });
    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    useEffect( () => {
        themeService.getDefaultTheme().then(response => {
            setTheme(response.payload);
            setIsLoading(false);
        })
    }, [])

    return [ isLoading, setIsLoading, theme, setTheme ]
}

interface ImageFileSelectorProps {
    onFileSelection: (file: File | null) => void;
}

const ImageFileSelector: FunctionComponent<ImageFileSelectorProps> = props => {
    const onFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onFileSelection(event.target.files?.[0] ?? null);
    };

    return (
        <label htmlFor={'upload-button'}>
            <Input type="file" accept="image/*" onChange={onFileSelection} id="upload-button" />
            <Button variant="contained" component="span">
                Neues Hintergrundbild hochladen
            </Button>
        </label>
    );
};