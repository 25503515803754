import React from 'react';
import { appLayoutService } from '../layout/AppLayoutService';

export function GdprRoute() {
    appLayoutService.changeContext({ title: 'Startseite' })

    return (
        <div>
            Wenn Sie Ihre personenbezogenen Daten löschen lassen möchten, senden Sie bitte eine Mail mit einer
            entsprechenden Anfrage an <a href={"mailto:info@esono.de"}>info@esono.de</a>
        </div>
    );
}
