import { AbstractApiService } from './AbstractApiService';
import {
    ReadImportFileFieldPositionDto,
    ReadImportFileSkipLines,
} from '../../../backend-types/src/user/dto/read-import-file.dto';
import { ReadImportFileResponseDto, ReadImportFileField } from '../../../backend-types/types';

export interface ImportFileOptions {
    fieldPositions: ReadImportFileFieldPositionDto;
    skipLines: ReadImportFileSkipLines;
}

class UserImportService extends AbstractApiService {
    public async readFile(file: File, options: ImportFileOptions): Promise<ReadImportFileResponseDto> {
        const data = new FormData();
        data.append('file', file);

        for (const [i, value] of Object.entries<ReadImportFileField|null>(options.fieldPositions)) {
            if (value === null) {
                continue;
            }
            data.append(`positions[${i}]`, value);
        }

        for (const [i, value] of Object.entries<number>(options.skipLines)) {
            data.append(`skip[${i}]`, `${value}`);
        }

        const response = await this.fetch('user/import/read', {
            method: 'POST',
            headers: {
                // Content-Type will be set by fetch automatically based on the `FormData`
            },
            body: data,
        });

        return await response.json() as ReadImportFileResponseDto;
    }
}

export const userImportService = new UserImportService();
